import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar, Container, Box, Grid, Paper, Link } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Chip from '@material-ui/core/Chip';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";

const ConnectButton = styled(WalletDialogButton)``;

const CounterText = styled.span``; // add your styles here

const MintButtonWrapper = styled.div``; // add your styles here

const MintButton = styled(Button)`
  margin-top: 24px;
  padding: '24px 36px',
  font-size: 36px,
  font-weight: 600
`; // add your styles here

const ContainerWrapper = styled(Container)`
  margin-top: 36px;
  min-height: 100vh;
`; // add your styles here

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 350,
      flexGrow: 1,
    },
    media: {
      height: 350,
    },
    title: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));

const useButtonStyles = makeStyles({
  root: {
    textTransform: undefined,
    padding: '24px 24px',
    // minWidth: '250px',
    fontSize: '24px',
    fontWeight: 600,
    align: 'center'
  },
});
const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();


export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;
      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }

        console.log('TX status', status);

      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  return (
    <>
      <AppBar position="static" color='secondary'>
        <Toolbar>
          <img src="logo.png" alt="Kitten" height="35" width="auto" />
          <Typography variant="h6" className={classes.title}>
            NFTs
          </Typography>
          {!wallet ? (
            <ConnectButton>Connect Wallet</ConnectButton>
          ) : (
            <>
              <Box sx={{ marginRight: 24 }} >
                <strong>Wallet:</strong>
                {shortenAddress(wallet.publicKey.toBase58())}
              </Box>
              <Chip color="primary" label={balance?.toFixed(2) + ' SOL'} />
            </>
          )}
        </Toolbar>
      </AppBar>

      <ContainerWrapper>
        <Grid container spacing={5} justifyContent='center'>
          <Grid item xs={12}>
            <Typography gutterBottom variant='h3' align='center'>
              12 500 PumpEQ Hippos NFTs
            </Typography>
            <Typography gutterBottom variant='h5' align='center'>
              Computer Generated
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} container direction="row" alignItems="center" justifyContent="flex-end">
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image="characters.gif"
                  title="PumpEQ hippos NFTs"
                />
                <CardContent>
                  {!wallet ? (
                    <Typography gutterBottom variant="h5" component="h2" align='center'>
                      You need to connect your wallet first
                    </Typography>
                  ) : (
                    <Typography gutterBottom variant="h5" component="h2" align='center'>
                      Sold {itemsRedeemed} / {itemsAvailable}
                    </Typography>
                  )}
                </CardContent>
              </CardActionArea>
            </Card>

          </Grid>
          <Grid item xs={12} sm={6} container direction="row" alignItems="center" justifyContent="flex-start">
            {!isActive ? (
              <Box>
                <Typography gutterBottom variant="h4" component="h4" align='center'>
                  Get ready in
                </Typography>
                <Typography gutterBottom variant="h2" component="h2" align='center'>
                  <Countdown
                    date={startDate}
                    onMount={({ completed }) => completed && setIsActive(true)}
                    onComplete={() => setIsActive(true)}
                    renderer={renderCounter}
                  />
                </Typography>
              </Box>
            ) : !wallet ? (
              <>
                <Alert variant="outlined" severity="info" style={{ marginBottom: 36 }}>
                  <Typography gutterBottom variant="h6" component="h6"> Each NFT cost 0.2 SOL + fees (less than 0.05 SOL). You must have at least 0.25 SOL for each NFT. </Typography>
                  <Typography gutterBottom variant="h6" component="h6">
                    If you don't have a wallet yet,<Link href="https://pumpeq.com/solana-wallet-the-definitive-guide/" target="_blank" onClick={preventDefault} color='secondary' style={{ padding: 4 }}>
                      check this guide on how to create your wallet
                    </Link>
                    and transfer Solana to it.
                  </Typography>
                </Alert>
                <ConnectButton>Connect Wallet</ConnectButton>
              </>
            ) : (
              <MintButton
                disabled={isSoldOut || isMinting || !isActive || !wallet}
                onClick={onMint}
                variant="contained"
                color="primary"
                className={buttonClasses.root}
              >
                {isSoldOut ? (
                  "SOLD OUT"
                ) : isActive ? (
                  isMinting ? (
                    <>
                      <CircularProgress style={{ marginRight: 12 }} />
                      <Typography gutterBottom variant="h6"> Processing</Typography>
                    </>
                  ) : (
                    "Buy for 0.2 SOL")
                ) : (
                  <Countdown
                    date={startDate}
                    onMount={({ completed }) => completed && setIsActive(true)}
                    onComplete={() => setIsActive(true)}
                    renderer={renderCounter}
                  />
                )}
              </MintButton>
            )}
          </Grid>
          <Grid item xs={12} container direction="row" alignItems="center" justifyContent="center">
            <Typography gutterBottom variant='h4' align='center' style={{ marginTop: '36px' }}>
              Join our mission
            </Typography>
          </Grid>

          <Grid item xs={6} sm={2}>
            <Paper className={classes.paper} >
              <Link href="https://pumpeq.com/#roadmap" target="_blank" onClick={preventDefault} variant='h5' color='secondary'>
                Roadmap
              </Link>
            </Paper>

          </Grid>
          <Grid item xs={6} sm={2}>
            <Paper className={classes.paper}>
              <Link href="https://pumpeq.com/#rarity" target="_blank" onClick={preventDefault} variant='h5' color='secondary'>
                Rarity
              </Link>
            </Paper>

          </Grid>
          <Grid item xs={6} sm={2}>
            <Paper className={classes.paper}>
              <Link href="https://pumpeq.com/start-now" target="_blank" onClick={preventDefault} variant='h5' color='secondary'>
                Books
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Paper className={classes.paper}>
              <Link href="https://www.amazon.com/gp/product/B08Y75F3SG" target="_blank" onClick={preventDefault} variant='h5' color='secondary'>
                Amazon
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Paper className={classes.paper}>
              <Link href="https://twitter.com/pumpeqbooks" target="_blank" onClick={preventDefault} variant='h5' color='secondary'>
                Twitter
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Paper className={classes.paper}>
              <Link href="https://discord.gg/vpAS2WV8kW" target="_blank" onClick={preventDefault} variant='h5' color='secondary'>
                Discord
              </Link>
            </Paper>
          </Grid>
        </Grid>

      </ContainerWrapper>
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          <Typography gutterBottom variant='h5'>
            {alertState.message}
          </Typography>
          
        </Alert>
      </Snackbar>
    </>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24}h {minutes}m {seconds}s
    </CounterText>
  );
};

export default Home;
